<template>
  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4_185)">
      <path
        d="M15.2959 10.3345C15.2959 11.2546 15.3177 12.1762 15.2872 13.0963C15.2712 13.589 15.4487 13.9015 15.856 14.1748C17.4068 15.2126 18.9373 16.281 20.475 17.3377C21.2155 17.8465 21.2213 17.9104 20.5957 18.5921C18.5488 20.8248 15.5185 21.6039 12.7733 20.6024C9.95388 19.5718 8.10046 16.9772 8.00299 13.9204C7.88661 10.288 10.9271 6.93322 14.5801 6.64832C15.069 6.61053 15.3075 6.7617 15.2988 7.28207C15.2828 8.29955 15.2945 9.31704 15.2945 10.3345H15.2974H15.2959Z"
        fill="#6185DB"
      />
      <path
        d="M32.2225 29.9022C32.2225 26.9718 32.221 24.0415 32.2225 21.1126C32.2225 20.0515 32.4334 19.8465 33.5042 19.8393C34.1588 19.8349 34.8135 19.8436 35.4667 19.8393C36.0879 19.8364 36.4036 20.1663 36.4676 20.7579C36.4938 20.9978 36.4894 21.2419 36.4894 21.4832C36.4894 27.1012 36.4894 32.7192 36.4894 38.3371C36.4894 38.6031 36.4894 38.8706 36.4574 39.1351C36.389 39.6977 36.0806 40.0014 35.4885 39.9956C34.7611 39.9884 34.0337 40.0029 33.3078 39.9927C32.4611 39.9826 32.2254 39.7558 32.2239 38.9084C32.2167 35.9053 32.221 32.9023 32.221 29.9007L32.2225 29.9022Z"
        fill="#6185DB"
      />
      <path
        d="M24.6576 31.8718C24.6576 29.5505 24.6547 27.2291 24.659 24.9064C24.6605 23.9369 24.9296 23.6679 25.9131 23.6476C26.5415 23.6345 27.1715 23.6476 27.8014 23.6476C28.5084 23.6476 28.875 24.0066 28.9099 24.7072C28.9201 24.9006 28.926 25.0939 28.926 25.2872C28.926 29.6638 28.9289 34.0419 28.926 38.4186C28.926 39.8314 28.7557 39.9957 27.3402 39.9957C26.8078 39.9957 26.2753 40.0015 25.7428 39.9928C24.8991 39.9797 24.6663 39.7558 24.6634 38.907C24.6547 36.5609 24.6605 34.2149 24.6605 31.8689L24.6576 31.8718Z"
        fill="#6185DB"
      />
      <path
        d="M17.6658 8.66441C17.6658 7.69635 17.7022 6.72683 17.6542 5.76167C17.6178 5.04362 17.9917 4.94187 18.5503 5.02472C21.3421 5.43898 23.3381 6.92451 24.4015 9.5409C25.2541 11.6384 25.0518 13.7068 23.9869 15.701C23.7396 16.1647 23.4937 16.265 23.0282 15.9351C21.4672 14.8289 19.8902 13.7475 18.3001 12.6835C17.8258 12.3666 17.628 11.9959 17.6542 11.4218C17.6978 10.5046 17.6658 9.58305 17.6658 8.66296V8.66441Z"
        fill="#6185DB"
      />
      <path
        d="M17.0911 33.9343C17.0911 32.3107 17.0853 30.6886 17.094 29.065C17.0984 28.2175 17.3326 27.9864 18.175 27.9748C18.8776 27.9646 19.5818 27.9864 20.2844 27.9704C20.9478 27.9544 21.2751 28.2829 21.3231 28.9182C21.3493 29.2801 21.3552 29.6435 21.3566 30.0069C21.3595 32.8166 21.3624 35.6263 21.3566 38.436C21.3537 39.8096 21.1588 39.9956 19.8174 39.9971C19.2602 39.9971 18.7016 40.0044 18.1444 39.9942C17.3399 39.9797 17.1028 39.7529 17.097 38.9491C17.0868 37.2775 17.094 35.6059 17.094 33.9343H17.0911Z"
        fill="#6185DB"
      />
      <path
        d="M34.2433 13.0251C33.9378 12.8797 33.6308 13.0192 33.3267 13.0192C32.9005 13.0192 32.531 12.8884 32.4931 12.4015C32.4553 11.9087 32.787 11.7241 33.2307 11.7009C34.1982 11.6486 35.1656 11.5977 36.1331 11.5512C36.7935 11.5192 37.0787 11.839 36.9841 12.4887C36.8459 13.4466 36.7033 14.403 36.5608 15.3595C36.5011 15.7635 36.3105 16.0688 35.8596 16.0325C35.3562 15.9932 35.2529 15.6196 35.282 15.1908C35.3067 14.8405 35.346 14.4917 35.3926 14.0077C34.7583 14.5309 34.2214 14.9714 33.6861 15.4147C26.511 21.3495 18.4514 25.7029 9.68768 28.7961C9.22942 28.9574 8.78571 29.0126 8.58785 28.4792C8.38127 27.9254 8.80171 27.7015 9.23961 27.5446C14.0463 25.8366 18.6769 23.7435 23.0879 21.178C26.8471 18.9919 30.3851 16.4918 33.6977 13.6748C33.881 13.5178 34.0512 13.3448 34.22 13.1719C34.2462 13.1457 34.236 13.0847 34.2433 13.0265V13.0251Z"
        fill="#6185DB"
      />
      <path
        d="M9.52616 35.9955C9.52616 35.0521 9.51888 34.1088 9.52761 33.1654C9.53634 32.2918 9.77202 32.0622 10.6624 32.052C11.3403 32.0433 12.0182 32.0607 12.6962 32.0491C13.3436 32.0375 13.7378 32.3383 13.7451 32.9808C13.7669 35.0114 13.764 37.0435 13.7451 39.0741C13.7393 39.6686 13.4105 40.0073 12.7747 39.9985C12.0008 39.9869 11.2254 39.9956 10.45 39.9956C9.81566 39.9956 9.52616 39.6642 9.52761 39.045C9.53052 38.029 9.52761 37.013 9.52761 35.9969L9.52616 35.9955Z"
        fill="#6185DB"
      />
    </g>
    <defs>
      <clipPath id="clip0_4_185">
        <rect width="29" height="35" fill="white" transform="translate(8 5)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
  export default {}
</script>
