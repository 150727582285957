
  import Vue from 'vue'
  import UpdatePwa from '@/components/updatePwa.vue'
  import GlobalNotification from '@/components/global/GlobalNotification.vue'
  import { Component, Watch } from 'vue-property-decorator'
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import NetworkDetector from '@/components/NetworkDetector.vue'
  import { Query } from '@/entities/public/Resource/interfaces'
  import { formFilter } from '@/graphql/generated-types'
  import { Schedule } from '@/entities/persons'
  import { Debounce } from '@/utils/decorators'
  import { UserData } from '@/store/user/state'
  import dayjs from 'dayjs'

@Component({
  components: { UpdatePwa, GlobalNotification, NetworkDetector },
  methods: {
    ...mapActions('app', ['setMobile', 'setIsResize']),
    ...mapMutations('app', ['setSystem', 'setSchedule']),
    ...mapActions('resources/form', ['fetchData']),
    ...mapActions('user', ['sendHeartbeat']),
    ...mapActions('user', ['logout']),
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
})
  export default class App extends Vue {
  setMobile!: (payload: boolean) => void
  setIsResize!: (payload: number) => void
  setSystem!: (payload: string) => void
  setSchedule!: (payload: Array<Schedule>) => void
  sendHeartbeat!: (id: number) => Promise<void>
  logout!: () => void;
  user!: UserData

  fetchData!: (payload: {
    query: Query
    filter?: formFilter
    offset?: number
    limit?: number
    force?: boolean
    distinct?: Array<string>
  }) => Promise<any>;

  showInactivityDialog: boolean = false;
  totalInactivityTime: number = 30;
  dialogDisplayTime: number = 5;
  inactivityCountdown: number = 300;
  inactivityCountdownInterval: number | null = null;
  heartbeatInterval: number | null = null

  systemLogoutTime = null

  async mounted () {
    await this.getSystem()

    const events = ['mousemove', 'mousedown', 'keypress', 'touchmove', 'scroll']
    events.forEach(event => {
      window.addEventListener(event, this.resetActivity)
    })
  }

  @Debounce()
  async getSystem () {
    if (localStorage.getItem('apollo-token')) {
    const system = (await this.fetchData({
       query: { name: 'find', model: 'Person' },
      filter: { type: { name: { _eq: 'system' } } },
    }))[0]

    this.systemLogoutTime = system?.metadata?.userLogoutTime || { time: 30, unit: 'minute' }

    const time = {
      hour: 60,
      day: 1440,
      month: 43200,
    }
    const unitMultiplier = time[this.systemLogoutTime.unit] || 1
    this.totalInactivityTime = this.systemLogoutTime.time * unitMultiplier
    this.setSchedule(system?.metadata?.schedule)
    this.setSystem(system?.alias)
    }
  }

  @Watch('$vuetify.breakpoint', { immediate: true, deep: true }) onBreakpointChange (val) {
    // if this width changes, update resourceView DT mobile breakpoint
    this.setMobile(val.width < 875)
  }

  @Watch('$vuetify.breakpoint.width', { immediate: true, deep: true }) onWidthChange (val) {
    this.setIsResize(val)
  }

  @Watch('$vuetify.breakpoint.height', { immediate: true, deep: true }) onHeightChange (val) {
    this.setIsResize(val)
  }

  @Watch('user', { immediate: true, deep: true })
  async onUserChange (val) {
    if (!val?.id) return

    if (this.heartbeatInterval) {
      window.clearInterval(this.heartbeatInterval)
      this.heartbeatInterval = null
    }

    await this.sendHeartbeat(val.id)

    this.heartbeatInterval = window.setInterval(() => {
      const lastActivityTime = localStorage.getItem('lastActivityTime')

      if (!lastActivityTime) {
        localStorage.setItem('lastActivityTime', dayjs().toISOString())
        return
      }

      const currentTime = dayjs()
      const lastActivity = dayjs(lastActivityTime)
      const timeElapsed = currentTime.diff(lastActivity, 'minute')

      // Calcular el tiempo restante antes del logout
      const timeRemaining = this.totalInactivityTime - timeElapsed

      if (timeRemaining <= this.dialogDisplayTime && timeRemaining > 0) {
        // Mostrar el diálogo de inactividad si aún no se está mostrando
        if (!this.showInactivityDialog) {
          this.showInactivityDialog = true
          // Iniciar cuenta regresiva para el logout
          this.inactivityCountdown = timeRemaining * 60 // Convertir a segundos
          this.startInactivityCountdown()
        }
      } else if (timeRemaining <= 0) {
        // Tiempo agotado, cerrar sesión
        this.logout()
      } else {
        // El usuario todavía está activo, enviar heartbeat
        this.sendHeartbeat(val.id)
      }
    }, 30000) // Verificar cada 30 segundos
  }

  startInactivityCountdown () {
    if (this.inactivityCountdownInterval) return

    this.inactivityCountdownInterval = window.setInterval(() => {
      this.inactivityCountdown--

      if (this.inactivityCountdown <= 0) {
        // Tiempo agotado, cerrar sesión
        this.logout()
        this.clearInactivityCountdown()
      }
    }, 1000) // Actualizar cada segundo
  }

  clearInactivityCountdown () {
    if (this.inactivityCountdownInterval) {
      window.clearInterval(this.inactivityCountdownInterval)
      this.inactivityCountdownInterval = null
    }
    this.showInactivityDialog = false
    this.inactivityCountdown = 60
  }

  resetActivity () {
    // Usuario interactuó, reiniciar temporizadores
    localStorage.setItem('lastActivityTime', dayjs().toISOString())
    this.clearInactivityCountdown()
    this.showInactivityDialog = false
  }

  beforeDestroy () {
    const events = ['mousemove', 'mousedown', 'keypress', 'touchmove', 'scroll']
    events.forEach(event => {
      window.removeEventListener(event, this.resetActivity)
    })

    // Limpiar temporizadores
    if (this.heartbeatInterval) {
      window.clearInterval(this.heartbeatInterval)
    }
    this.clearInactivityCountdown()
  }
  }
