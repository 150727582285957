import Google from './Google.vue'
import Genio from './GenioIso.vue'
import Pin from './Pin.vue'
import Unppinned from './UnPinned.vue'
import CarAlert from './CarAlert.vue'
import CarTractionControl from './CarTractionControl.vue'
import FullMotor from './FullMotor.vue'
import ChileAutos from './ChileAutos.vue'
import Yapo from './Yapo.vue'
import Dealer from './Dealer.vue'
import MercadoLibre from './MercadoLibre.vue'
import MarketPlaceFacebook from './MarketPlaceFacebook.vue'
import CarCash from './CarCash.vue'
import GStar from './GStar.vue'
import GStarOff from './GStarOff.vue'
import GStarHalf from './GStarHalf.vue'
import ReFound from './ReFound.vue'
import PersonalContact from './PersonalContact.vue'
import GStarInspector from './GStarInspector.vue'
import GStarHalfInspector from './GStarHalfInspector.vue'
import Negotiation from './Negotiation.vue'
import OfficeStar from './OfficeStar.vue'
import CarClock from './CarClock.vue'
import CarScheduledInspection from './CarScheduledInspection.vue'
import CarSuccessfullPurchase from './CarSuccessfullPurchase.vue'
import Wheel from './Wheel.vue'
import Charity from './Charity.vue'
import GExecutiveFinancing from './GExecutiveFinancing.vue'
import GSupervisorFinancing from './GSupervisorFinancing.vue'
import Cylinder from './Cylinder.vue'
import Telegram from './Telegram.vue'
import CarKey from './CarKey.vue'
import KeyWithdrawal from './KeyWithdrawal.vue'
import DashboardExecutive from '@/components/icons/DashboardExecutive.vue'
import DashboardSupervisor from '@/components/icons/DashboardSupervisor.vue'
import flags from '@/components/icons/flags'
import Datatable from '@/components/icons/Datatable.vue'
import Kpi from '@/components/icons/Kpi.vue'

export const icons = {
  google: {
    component: Google,
  },
  genio: {
    component: Genio,
  },
  pin: {
    component: Pin,
  },
  unpinned: { component: Unppinned },
  chile_auto: {
    component: ChileAutos,
  },
  yapo: {
    component: Yapo,
  },
  dealer: {
    component: Dealer,
  },
  mercado_libre: {
    component: MercadoLibre,
  },
  car_alert: {
    component: CarAlert,
  },
  car_key: {
    component: CarKey,
  },
  key_withdrawal: {
    component: KeyWithdrawal,
  },
  full_motor: {
    component: FullMotor,
  },
  car_cash: {
    component: CarCash,
  },
  g_star: {
    component: GStar,
  },
  g_star_off: {
    component: GStarOff,
  },
  g_star_half: {
    component: GStarHalf,
  },
  re_found: {
    component: ReFound,
  },
  g_star_inspector: {
    component: GStarInspector,
  },
  g_star_half_inspector: {
    component: GStarHalfInspector,
  },
  g_car_traction_control: {
    component: CarTractionControl,
  },
  negotiation: {
    component: Negotiation,
  },
  office_star: {
    component: OfficeStar,
  },
  car_clock: {
    component: CarClock,
  },
  car_scheduled_inspection: {
    component: CarScheduledInspection,
  },
  car_successfull_purchase: {
    component: CarSuccessfullPurchase,
  },
  g_wheel: {
    component: Wheel,
  },
  charity: {
    component: Charity,
  },
  g_executive_financing: {
    component: GExecutiveFinancing,
  },
  g_supervisor_financing: {
    component: GSupervisorFinancing,
  },
  cylinder: {
    component: Cylinder,
  },
  telegram: {
    component: Telegram,
  },
  market_place_facebook: {
    component: MarketPlaceFacebook,
  },
  personal_contact: {
    component: PersonalContact,
  },
  executive_dashboard: {
    component: DashboardExecutive,
  },
  supervisor_dashboard: {
    component: DashboardSupervisor,
  },
  ...flags,
  datatable: {
    component: Datatable,
  },
  kpi: {
    component: Kpi,
  },
}

export default icons
